<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('salesInstallmentsPayments.form.addSalesPayment')"
          :description="
            $t('salesInstallmentsPayments.form.FromHereYouCanCreateaNewSalesPayment')
          "
          v-if="$route.name == 'salesInstallmentsPaymentsCreate'"
        />
        <addHeader
          :title="$t('salesInstallmentsPayments.form.SalesPaymentadjustment')"
          :description="$t('salesInstallmentsPayments.form.FromHereYouCanModifyTheSalesPaymentInformation') "
          v-if="$route.name == 'salesInstallmentsPaymentsEdit'"
        />

        <div class="mb-4 row">
          <connectionInput
            :title="$t('salesInstallmentsPayments.form.NumberingTheBatch')"
            :group="$t('salesInstallmentsPayments.form.Groups')"
            v-model="item.invoice_group"
            :hasErorr="errors.invoice_group"
            :disabled=" $user.admin || $user.role.sales_payments_edit_item ? false: true"
            :error="$t('salesInstallmentsPayments.form.ThisFieldIsRequired')"
            :values="$database.invoiceGroups"
            v-if="$route.name == 'salesInstallmentsPaymentsCreate'"
          />
          <subFileInput :title="$t('invoices.form.invoiceFile')"
                     v-model="item.file"
                     :file="item.file"
                     :path="'invoices'"
                     :hasErorr="errors.file"
                     :error="$t('allerts.ChooseASuitableFile')"/>
          <formInput
          :title="$t('salesPayments.form.reference')"
          v-model="item.refrance"
          maxlength="255" />
          <formInput
            :title="$t('salesInstallmentsPayments.form.InvoiceCode')"
            v-model="item.code"
            :disabled="true"
            maxlength="255" 
            v-if="$route.name == 'salesInstallmentsPaymentsEdit'"
          />

          <formInput
            :title="$t('salesInstallmentsPayments.form.salesInstallmentCode')"
            v-model="item.sales_installment_code"
            :disabled="true"
            maxlength="255" 
          />

          <customerSearchInput
            :disabled="true"
            v-if="item.client_type == 0"
          />

          <supplierSearchInput
            :disabled="true"
            v-if="item.client_type == 1"
          />

          <div class="form-group row" :class="'col-md-6'">
            <label :class="'col-md-4'">{{
              $t('salesInstallmentsPayments.form.PaymentDate')
            }}</label>
            <div :class="'col-md-7'">
              <div class="icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>
                <VueCtkDateTimePicker
                  :label="$t('salesInstallmentsPayments.form.ChooseTimeAndDate')"
                  :button-now-translation="$t('salesInstallmentsPayments.form.Now')"
                  v-model="item.date"
                  :disabled=" $user.admin || $user.role.sales_payments_edit_item ? false: true"
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"
                />
              </div>
            </div>
          </div>

          <dvider
            :title="$t('salesInstallmentsPayments.form.AccountsInformation')"
            :description="
              $t('salesInstallmentsPayments.form.AccountInformationDetails')
            "
          />

          <monyInput
            :title="$t('salesInstallmentsPayments.form.ReceivedAmount')"
            v-model="item.cost"
            :hasErorr="errors.cost"
            :error="$t('allerts.theAmountReceivedMustBeGreaterThan')"
          />

          <connectionInput
            :title="$t('salesInstallmentsPayments.form.Treasury')"
            :group="$t('salesInstallmentsPayments.form.safes')"
            v-model="item.safe_id"
            :hasErorr="errors.safe_id"
            :error="$t('salesInstallmentsPayments.form.ThisFieldIsRequired')"
            :values="$database.safes"
            :disabled="$route.name == 'salesInstallmentsPaymentsEdit'"
          />


          <connectionInput
            :title="$t('salesInstallmentsPayments.form.PaymentMethod')"
            :group="$t('salesInstallmentsPayments.form.PaymentMethods')"
            v-model="item.payment_method_id"
            :hasAdd="true"
            :open="'addPaymentMethod'"
            :values="$database.paymentMethods"
            :value="item.payment_fee_id"
            :disabled="
              $user.admin || $user.role.sales_payments_edit_item
                ? false
                : true
            "
          />
          
          <div class="col-6"></div>

          <dvider
            :title="$t('salesInstallmentsPayments.form.PaymentNotes')"
            :description="$t('salesInstallmentsPayments.form.PaymentNotesDetails')"
          />

          <formTextarea
            :title="$t('salesInstallmentsPayments.form.thatAbout')"
            v-model="item.description"
          />
          <formTextarea
            :title="$t('salesInstallmentsPayments.form.Note')"
            v-model="item.notes"
          />
          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'salesInstallmentsPaymentsCreate' && !$parent.stopEdit"
          >
            {{ $t('salesInstallmentsPayments.form.addSalesPayment') }}
          </a>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'salesInstallmentsPaymentsEdit' && !$parent.stopEdit"
          >
            {{ $t('salesInstallmentsPayments.form.SalesPaymentadjustment') }}
          </a>
          <loading v-if="$parent.stopEdit"/>
        </div>
      </div>
    </div>
    <addPaymentMethod v-if="model == 'addPaymentMethod'"/>
    <addPaymentFee v-if="model == 'addPaymentFee'"/>
  </div>
</template>
<script>
import axios from "axios";

import formInput from "../elements/add/form/formInput.vue";
import formTextarea from "../elements/add/form/formTextarea.vue";
import connectionInput from "../elements/add/form/connectionInput.vue";
import monyInput from "../elements/add/form/monyInput.vue";
import selectInput from "../elements/add/form/selectInput.vue";
import checkInput from "../elements/add/form/checkInput.vue";
import dvider from "../elements/add/dvider.vue";
import addHeader from "../elements/add/header.vue";
import loading from "../elements/add/loading.vue";
import subFileInput from "../elements/add/form/subFileInput.vue";

import addPaymentMethod from "../elements/popupForms/addPaymentMethod.vue";
import addPaymentFee from "../elements/popupForms/addPaymentFee.vue";

import customerTaxIdInput from "../elements/add/form/search/customerTaxIdInput.vue";
import customerSearchInput from "../elements/add/form/search/customerNameInput.vue";

import supplierTaxIdInput from "../elements/add/form/search/supplierTaxIdInput.vue";
import supplierSearchInput from "../elements/add/form/search/supplierNameInput.vue";

import partnerTaxIdInput from "../elements/add/form/search/partnerTaxIdInput.vue";
import partnerSearchInput from "../elements/add/form/search/partnerNameInput.vue";

import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

export default {
  data() {
    return {
      path: "/salesInstallmentsPayments",
      item: {
        invoice_group: this.$option.sales_installment_payment__invoice_group_id,
        customer_id: 0,
        supplier_id: 0,
        partner_id: 0,
        project_id: 0,
        project_item_id: 0,
        customer: {
          name: "",
          mobile: "",
          address1: "",
          balance: 0,
        },
        supplier: {
          name: "",
          mobile: "",
          address1: "",
          balance: 0,
        },
        partner: {
          name: "",
          mobile: "",
          address1: "",
          balance: 0,
        },
        client_type: 0,
        invoice_id: 0,
        payment_method_id: 0,
        payment_fee_id: [],
        bank_tax: 0,
        safe_id: 0,
        date: "",
      },
      selectedPaymentFees: [],
      model: false,
      errors: {
        invoice_group: false,
        name: false,
        cost: false,
        safe_id: false,
      },
      print: this.$route.name == "salesInstallmentsPaymentsCreate" ? true : false,
    };
  },
  mounted() {
    this.$updateDatabase([
      "invoiceGroups",
      "customers",
      "suppliers",
      "projects",
      "projectItems",
      "safes",
      "paymentMethods",
      "paymentFees",
    ]);
    this.$parent.stopEdit = true;
    if (this.$route.name == "salesInstallmentsPaymentsEdit") {
      this.getItem();
    } else {
      

      var params = this.$getParams()
      if (params.customer_id) {
        var customer = this.$database.customers.find(
          (el) => el.id == params.customer_id
        );
        this.item.customer_id = params.customer_id;
        this.item.customer = customer;
      }
      if (params.safe_id) {
        var safe = this.$database.safes.find(
          (el) => el.id == params.safe_id
        );
        this.item.safe_id = params.safe_id;
      } else {
        if (this.$database.safes.length == 1) {
          this.item.safe_id = this.$database.safes[0].id;
        }
      }
      
    }
    if (this.$route.name == "salesInstallmentsPaymentsCreate") {
      this.item.date = this.$nowDate()
      this.getSalseInstallments()
    }
    var elm = this;
      window.addEventListener("keydown", function (e) {
        if (e.key == "F1" || e.key == "Meta") {
          e.preventDefault();
          elm.saveItem();
        }
    })
  },
  methods: {
    getItem() {
      axios
        .get(this.$linkGnirator(this.path + "/" + this.$route.params.id))
        .then((response) => {
          this.item = response.data;
          this.$parent.stopEdit = false;
          if (Array.isArray(JSON.parse(this.item.payment_fee_id))) {
            JSON.parse(this.item.payment_fee_id).forEach((id) => {
              var paymentFee = this.$database.paymentFees.find(
                (el) => el.id == id
              );
              this.selectedPaymentFees.push(paymentFee);
            });
          }
        });
    },
    getSalseInstallments () {
      var params = this.$getParams()
      axios
        .get(this.$linkGnirator("/salesInstallments/" + params.sales_installment_id))
        .then((response) => {
          var data = response.data;
          this.item.sales_installment_id = data.id
          this.item.sales_installment_code = data.code
          this.item.client_type = data.client_type
          this.item.customer_id = data.customer_id
          this.item.supplier_id = data.supplier_id
          this.item.customer = this.$database.customers.find(el => el.id == data.customer_id)
          this.item.supplier = this.$database.suppliers.find(el => el.id == data.supplier_id)
          this.$parent.stopEdit = false;
          
        });
    },
    saveItem() {
      var error = 0;
      if (
        this.item.client_type == 0 &&
        (typeof this.item.customer_id === "undefined" ||
          this.item.customer_id == "")
      ) {
        error = 1;
        this.errors.name = true;
      }
      if (
        this.item.client_type == 1 &&
        (typeof this.item.supplier_id === "undefined" ||
          this.item.supplier_id == "")
      ) {
        error = 1;
        this.errors.name = true;
      }
      if (
        this.item.client_type == 2 &&
        (typeof this.item.partner.name === "undefined" ||
          this.item.partner.name == "") &&
        this.$route.name == "salesInstallmentsPaymentsCreate"
      ) {
        error = 1;
        this.errors.name = true;
      }
      if (
        typeof this.item.cost === "undefined" ||
        this.item.cost == "" ||
        this.item.cost == 0
      ) {
        error = 1;
        this.errors.cost = true;
      }
      if (
        (typeof this.item.invoice_group === "undefined" ||
          this.item.invoice_group == 0 ||
          this.item.invoice_group == "" ||
          this.item.invoice_group == null) &&
        this.$route.name == "salesInstallmentsPaymentsCreate"
      ) {
        error = 1;
        this.errors.invoice_group = true;
      }
      if (this.$route.name == "salesInstallmentsPaymentsCreate") {
        if (
          typeof this.item.safe_id === "undefined" ||
          this.item.safe_id == "" ||
          this.item.safe_id == 0
        ) {
          error = 1;
          this.errors.safe_id = true;
        }
      }
      if (error == 0) {
        this.item.date = this.$fixDate(this.item.date)
        if (this.$route.name == "salesInstallmentsPaymentsCreate") {
          
          this.$parent.aletText =
            this.$t('allerts.salesInstallmentsPaymentsuccessfullyAdde');
          this.$parent.alertType = "success";
          this.$addToLocalDatabase(this.item, this.path.substring(1));
          
          var safe = this.$database.safes.find(
            (el) => el.id == this.item.safe_id
          );
          safe.balance = safe.balance - 0 + (this.item.cost - 0);
          localStorage.database = JSON.stringify(this.$database);
          
          this.$router.go(-1);
          this.$parent.stopEdit = false;
        } else if (this.$route.name == "salesInstallmentsPaymentsEdit") {
          this.item._method = "PUT";
          this.$parent.stopEdit = true;
          axios
            .post(this.$linkGnirator(this.path + "/" + this.item.id), this.item)
            .then((response) => {
              if (response.data == "safe error") {
                this.$parent.aletText = "الموظف غير مربوط بخزينة";
                this.$parent.alertType = "danger";
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              } else {
                this.$parent.aletText =
                  this.$t('allerts.salesPaymentHasBeenModifiedSuccessfully');
                this.$parent.alertType = "success";
                this.$router.go(-1);
                this.$parent.stopEdit = false;
                return response;
              }
            });
        }
      } else {
        this.$parent.aletText = this.$t('allerts.pleaseMakeSureOfTheInput');
        this.$parent.alertType = "danger";
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },
  watch: {
    selectedPaymentFees: function (val) {
      this.item.payment_fee_id = val.map((el) => el.id);
    },
  },

  components: {
    formInput,
    dvider,
    addHeader,
    monyInput,
    selectInput,
    formTextarea,
    connectionInput,
    checkInput,
    addPaymentMethod,
    addPaymentFee,
    loading,

    customerTaxIdInput,
    customerSearchInput,

    supplierTaxIdInput,
    supplierSearchInput,

    partnerTaxIdInput,
    partnerSearchInput,

    VueCtkDateTimePicker,
    subFileInput,
  },
};
</script>
